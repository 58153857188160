<template>
  <v-container v-if="!loading">
    <h3>Manage Categories:</h3>

    <v-row>
      <v-col cols sm="12" v-if="foodCategories">
        <v-data-table
          :headers="headers"
          :items="foodCategories"
          :sort-by="['order', 'fat']"
          :sort-desc="[false, true]"
          multi-sort
          class="elevation-1"
        >
          <template v-slot:item.image="{ item }">
            <v-img contain width="50" :src="item.image" />
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)"
              >mdi-pencil</v-icon
            >
            <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-dialog v-model="dialog" persistent max-width="600px">
          <template v-slot:activator="{ on }">
            <v-btn fab v-on="on" color="info">
              <v-icon small>add</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">New Item</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      label="Category Name*"
                      v-model="item.name"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6" sm="4">
                    <v-select
                      label="Business Type"
                      :items="['all', 'restaurant', 'cfo', 'other']"
                      v-model="item.business_type"
                    ></v-select>
                  </v-col>
                  <v-col cols="6" sm="2">
                    <v-text-field
                      label="Order"
                      v-model="item.order"
                      required
                      type="number"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      label="Image"
                      v-model="item.image"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">Close</v-btn>
              <v-btn
                v-if="item.id"
                color="blue darken-1"
                :disabled="!item.name"
                text
                @click="saveCategory"
                >Save</v-btn
              >
              <v-btn
                v-else
                color="blue darken-1"
                :disabled="!item.name"
                text
                @click="addCategory"
                >Add</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      dialog: false,
      item: {},

      headers: [
        {
          text: 'Category',
          align: 'start',
          value: 'name'
        },
        { text: 'Type', value: 'business_type' },
        { text: 'Image', value: 'image' },
        { text: 'Sort Order', value: 'order' },
        { text: 'Actions', value: 'actions', sortable: false }
      ]
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      foodCategories: 'foodCategories'
    })
  },

  mounted() {
    this.$store.dispatch('loadFoodTypesSimple', { type: 'category' })
  },
  methods: {
    close() {
      this.dialog = false
      this.item = {}
    },
    editItem(item) {
      this.editedIndex = this.foodCategories.indexOf(item)
      this.item = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem(item) {
      const index = this.foodCategories.indexOf(item)

      if (confirm('Are you sure you want to delete this item?')) {
        this.foodCategories.splice(index, 1)
        this.$store.dispatch('removeFoodTypes', { id: item.id }).then(() => {
          this.$store.dispatch('loadFoodTypesSimple', { type: 'category' })
          this.$store.dispatch('setMessage', {
            title: 'Deleted',
            body: ''
          })
        })
      }
    },
    addCategory() {
      this.dialog = false
      const found = this.foodCategories.find(
        c => c.name.toLowerCase() === this.item.name.toLowerCase()
      )

      if (found) {
        this.$store.dispatch('setError', { message: `type already exists` })

        return
      }
      this.item.type = 'category'
      this.item.order = this.foodCategories.length + 1
      this.$store.dispatch('saveFoodTypes', this.item).then(() => {
        this.$store.dispatch('setMessage', {
          title: 'Added',
          body: 'New item added'
        })
        this.item = {}
        this.$store.dispatch('loadFoodTypesSimple', { type: 'category' })
      })
    },
    saveCategory() {
      this.dialog = false
      this.item.order = this.item.order
        ? parseInt(this.item.order)
        : this.foodCategories.length + 1
      this.$store.dispatch('saveFoodTypes', this.item).then(() => {
        this.$store.dispatch('setMessage', {
          title: 'Saved',
          body: ''
        })
        this.item = {}
        this.$store.dispatch('loadFoodTypesSimple', { type: 'category' })
      })
    }
  }
}
</script>
